import React from 'react';
import type { HLocation } from '@reach/router';
import qs from 'query-string';

import { loginUserWithGoogle } from '../../store/session';
import OAuthCallbackModal from '../../views/Modals/OAuthCallbackModal';
import { Index } from '../index';

type OAuthResponse =
  | {
      access_token: string;
      state?: string;
    }
  | { error: string };

export default function SignGooglePage() {
  return (
    <Index>
      <OAuthCallbackModal
        action={loginUserWithGoogle}
        parseResponse={(location: HLocation) => {
          const fragments = qs.parse(location.hash) as OAuthResponse;
          if ('error' in fragments) {
            throw new Error(fragments.error);
          }
          if ('access_token' in fragments) {
            let original_uri;
            if ('state' in fragments) {
              original_uri = qs.parse(fragments.state).original_uri;
            }
            return {
              access_token: fragments.access_token,
              original_uri,
            };
          }
          throw new Error('flash.googleSignInError');
        }}
      />
    </Index>
  );
}
