import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useLocation } from '@gatsbyjs/reach-router';
import type { HLocation } from '@reach/router';
import { navigate } from 'gatsby';

import Detached from '../Detached';
import Loader from '../Loader';
import Modal from '../Modal';
import T from '../Translate';

interface ParsedResponse {
  access_token: string;
  original_uri?: string;
}

interface Props {
  parseResponse(location: HLocation): ParsedResponse;
  action(access_token: string): ActionReturnType | ThunkAction;
}

export default function OAuthCallbackModal(props: Props) {
  const dispatch = useDispatch();
  const location = useLocation();
  const [opened, setOpened] = useState(false);
  const [error, setError] = useState<string>(null);

  useEffect(() => {
    setOpened(true);
    try {
      const response = props.parseResponse(location);
      dispatch(props.action(response.access_token));
      if (response.original_uri) {
        navigate(response.original_uri);
      }
    } catch (error) {
      setError(error.message);
    }
  }, []);

  if (!opened) return null;

  return (
    <Detached container="modal">
      <Modal closeModal={() => setOpened(false)}>
        <div className="modal-body p-5">{error ? <T id={error} optional /> : <Loader />}</div>
      </Modal>
    </Detached>
  );
}
